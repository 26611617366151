<template>
     <modal-vue @onChange="onChangeModal" :modal="modal">
        <div class="create-user">
        <admin-header-vue alias="" pageName="Update User" />
        <form @submit.prevent="handleSubmit">
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeFirstName"
                label="First Name" />
              <div v-if="submitted && isFirstNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeLastName"
                label="Last Name" />
              <div v-if="submitted && isLastNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="submit-button">
                <b-button @click.prevent="handleSubmit">Update</b-button>
              </div>
            </div>
          </div>
        </form>
        </div>
     </modal-vue>
</template>

<script>
import { mapActions } from 'vuex'
import ModalVue from '../Admin/components/Modal.vue'
import AdminHeaderVue from '../Admin/components/AdminHeader.vue'
import InputVue from '../Admin/components/Input.vue'
import { allowedNamePattern } from '@/util/util'

export default {
  name: 'CreateUser',
  components: {
    ModalVue,
    AdminHeaderVue,
    InputVue
  },
  data () {
    return {
      modal: true,
      submitted: false,
      user: {
        firstName: null,
        lastName: null
      },
      isFirstNameInvalid: true,
      isLastNameInvalid: true,
      isFirstNamePatternInvalid: false,
      isLastNamePatternInvalid: false,
      namePatternErrorMessage: 'Please provide a valid input. Only letters, numbers, spaces, - and _ are allowed.'
    }
  },
  methods: {
    ...mapActions('user', ['updateSamlUser']),
    onChangeModal (value) {
      this.modal = value
      if (!this.modal) {
        // this.$router.replace(`/admin/client/${this.$route.params.id}/update`)
      }
    },
    onChangeFirstName (name, isInvalid) {
      this.user.firstName = name
      this.isFirstNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isFirstNamePatternInvalid = true
      } else {
        this.isFirstNamePatternInvalid = false
      }
    },
    onChangeLastName (name, isInvalid) {
      this.user.lastName = name
      this.isLastNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isLastNamePatternInvalid = true
      } else {
        this.isLastNamePatternInvalid = false
      }
    },
    handleSubmit (e) {
      this.submitted = true
      if (
        !(
          this.isFirstNameInvalid ||
          this.isLastNameInvalid|| 
          this.isFirstNamePatternInvalid || 
          this.isLastNamePatternInvalid
          )
      ) {
        this.updateSamlUser(this.user)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-user {
    background: var(--primary);
}
</style>
